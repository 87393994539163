<template>
  <div class="segments">
    <div>
      <router-link class="segments__link" to="/dashboard">
        <v-icon color="#002147" small center>mdi-chevron-left</v-icon>
        {{ $t("returnToInsights") }}
      </router-link>
    </div>
    <div class="segments__wrapper">
      <p class="segments__text">{{ $t("segments") }}</p>
      <div class="mt-n8" style="max-width: 200px;">
        <a-select
           v-model="$v.inputArea.$model"
          :validator="$v.inputArea"
          :items="areas"
          :label="$t('selectArea') + $t('required')"
          v-on:input="resetShow"
        ></a-select>
      </div>
      <p class="segments__text"> {{ $t("on") }}</p>
      <div class="mt-n8" style="max-width: 200px;">
        <a-select
          v-model="$v.inputTarget.$model"
          :validator="$v.inputTarget"
          :items="targets"
          :label="$t('selectTarget') + $t('required')"
          v-on:input="resetShow"
        ></a-select>
      </div>
      <div class="mt-n4"><v-btn class="segments__button" @click="apply"> {{ $t("run") }}</v-btn></div>
    </div>
    <div v-if="show">
      <table class="tables__container" style="margin-top: 50px">
        <thead class="tables__header">
          <tr class="tables__row">
            <th class="tables__cell-title" scope="col">{{ $t("share") }}</th>
            <th class="tables__cell-title" scope="col">{{ $t("mainBasket") }}</th>
            <th class="tables__cell-title" scope="col">{{ $t("impulsive") }}</th>
            <th class="tables__cell-title" scope="col">{{ $t("store") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in displayedSegments" :key="item.percentage">
            <td class="tables__cell">{{ item.percentage }}</td>
            <td class="tables__cell">{{ item.ingredients }}</td>
            <td class="tables__cell">{{ item.ingredient }}</td>
            <td class="tables__cell">{{ item.store }}</td>
          </tr>
        </tbody>
      </table>
      <v-pagination
        v-model="activePage"
        :length="totalPages"
        @input="changePage"
        color="primary"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ASelect from "@/components/ASelect.vue";
export default {
  name: "SegmentsView",

  components: {
    ASelect,
  },

  mixins: [validationMixin],
  validations: {
    // Form validations
    inputArea: { required },
    inputTarget: { required },
  },

  data() {
    return {
      inputArea: null,
      inputTarget: null,
      show: false,
      activePage: 1,
      itemsPerPage: 4,
      areas: ["Store 24B", "Cluster of stores"],
      targets: ["Same category", "Whole store"],
      segments: [
        {
          percentage: "20%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "19%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "17.4%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "15%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "13.4%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "20%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "19%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "17.4%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "15%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "13.4%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "20%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "19%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "17.4%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "15%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
        {
          percentage: "13.4%",
          ingredients: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          ingredient: `"6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          store: "Store 24B",
        },
      ],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.segments.length / this.itemsPerPage);
    },
    displayedSegments() {
      const start = (this.activePage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.segments.slice(start, end);
    },
  },
  methods: {
    apply() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.show = true;
    },
    changePage(page) {
      this.activePage = page;
    },
    resetShow() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.segments {
  &__link {
    text-decoration: none;
    display: block;
    margin-bottom: 4rem;
  }

  &__text {
    color: #6a6f77;
  }

  &__wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &__button {
    color: white;
    background: #002147 !important;
  }
}
</style>
