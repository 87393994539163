<template>
  <v-select
    v-bind:value="value"
    :items="items"
    :multiple="multiple"
    :return-object="returnObject"
    :loading="loading"
    :error-messages="errors"
    :label="label"
    :hint="hint"
    :readonly="readonly"
    :item-text="itemtext"
    hide-details="auto"
    class="a-select"
    :prepend-inner-icon="prependInnerIcon"
    @input="$emit('input', $event)"
  ></v-select>
</template>
<script>
/**
 * @vue-prop {String,Number,Array,Object} value - Value
 * @vue-prop {Array} items - Items
 * @vue-prop {Boolean} multiple - Multiple
 * @vue-prop {Boolean} returnObject - Object
 * @vue-prop {Boolean} loading - Loading
 * @vue-prop {Object} validator - Validator
 * @vue-prop {String} label - Label
 * @vue-prop {String} hint - Hint
 * @vue-prop {Boolean} readonly - Read only
 * @vue-prop {String} prependInnerIcon - Icon
 * @vue-computed {String} errors
 */

export default {
  name: "a-select",

  props: {
    value: [String, Number, Array, Object],
    items: Array,
    multiple: Boolean,
    returnObject: Boolean,
    loading: Boolean,
    validator: Object,
    label: String,
    itemtext: String,
    hint: String,
    readonly: Boolean,
    prependInnerIcon: String,
  },

  computed: {
    errors() {
      const errors = [];

      if (!this.validator || !this.validator.$dirty) return errors;

      typeof this.validator.required !== "undefined" &&
        !this.validator.required &&
        errors.push(this.$t("errors.fields.REQUIRED_FIELD"));

      return errors;
    },
  },
};
</script>
